import React from 'react';
import LDH from './LeopardDataHelper';
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LeopardDropdownHelper from "./LeopardDropdownHelper";
import LeopardPermissionHelper from "./LeopardPermissionHelper";
import momenttz from "moment-timezone";
import notify from 'devextreme/ui/notify';

class LeopardDataHelper extends React.Component {
    constructor(props) {
        super(props);

        momentDurationFormatSetup(moment);
        if (typeof moment.duration.fn.format !== "function") {
            console.log("moment-duration-format not supported.");
        }
        if (typeof moment.duration.format !== "function") {
            console.log("moment-duration-format not supported.");
        }
    }

    static GetColumnFromJSON(json) {
        if (!LeopardDataHelper.IsObjectNull(json) && json.length > 0) {
            let columns = [];
            for (let key in json[0]) {
                if (json[0].hasOwnProperty(key)) {
                    columns.push({columnName: key});
                }
            }
            return columns;
        }
        return [];
    }

    static IsObjectNull(obj) {
        try {
            return (typeof obj === "undefined") || obj === null;
        } catch (ex) {
            return true;
        }
    }

    static IsValueEmpty(value) {
        try {
            return LDH.IsObjectNull(value) || value === "" ||
                value.toString().trim() === "";
        } catch (ex) {
            return true;
        }
    }

    static IsJsonString(str) {
        if (typeof str !== "string") {
            return false;
        }
        try {
            let json = JSON.parse(str);
            return (typeof json === 'object');
        } catch (ex) {
            return false;
        }
    }

    static ReplaceAll(str, search, replacement) {
        return str.split(search).join(replacement);
    }

    static IsValidEmailAddress(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    static GetAllLocalStorageItemKeys() {
        let archive = [];
        let keys = Object.keys(localStorage);
        for (let i = 0; i < keys.length; i++) {
            archive.push(keys[i]);
        }
        return archive;
    }

    static DeleteLocalStorageById(id) {
        localStorage.removeItem(id);
    }

    static IsValidIPAddress(ipAddress) {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipAddress)) {
            return true;
        }
        return false;
    }

    static MapJsonObjectForGridViewData(dataItem, resultJSON, prefix) {
        let objectKeys = Object.keys(dataItem);
        for (let i = 0; i < objectKeys.length; i++) {
            let objectName = objectKeys[i];
            let objectValue = dataItem[objectKeys[i]];
            let childKeyLength = 0;
            if (typeof objectValue === 'object' && !LDH.IsObjectNull(objectValue)) {
                childKeyLength = Object.keys(objectValue).length;
            }
            if (typeof objectValue === 'object' && !LDH.IsObjectNull(objectValue) &&
                LDH.IsObjectNull(objectValue._value) && childKeyLength >= 5) {
                this.MapJsonObjectForGridViewData(objectValue, resultJSON, objectName);
            } else {
                if (dataItem.hasOwnProperty(objectName) && !LDH.IsObjectNull(objectValue) &&
                    typeof objectValue.getMonth === 'function') {
                    objectValue = LDH.ConvertUTCDateToLocalDate(objectValue);
                }
                if (LDH.IsValueEmpty(prefix) === false) {
                    resultJSON[prefix + "#" + objectName] = objectValue;
                } else {
                    resultJSON[objectName] = objectValue;
                }
            }
        }
    }

    static GetFilteredUrlByDataViewParameters(parameters, originalUrl, dataViewId) {
        try {
            let filteredUrl = originalUrl;
            if (!LDH.IsObjectNull(parameters) && parameters.length > 0) {
                for (let k = 0; k < parameters.length; k++) {
                    if (filteredUrl.indexOf("{" + parameters[k].parameterName + "}") > -1) {
                        let controlId = parameters[k].parameterName;
                        let controlName = "dataViewParam_" + dataViewId + "_control_" + controlId;
                        let controlValue = window[controlName];
                        if (LDH.IsObjectNull(controlValue) || LDH.IsValueEmpty(controlValue)) {
                            controlValue = "";
                        }
                        if (!LDH.IsObjectNull(parameters[k].dataShapingForQuery) &&
                            !LDH.IsValueEmpty(parameters[k].dataShapingForQuery)) {
                            let javascript = parameters[k].dataShapingForQuery;
                            let dataName = "value";
                            let dataValue = JSON.stringify(controlValue);
                            controlValue = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, dataViewId);
                        }
                        let paramName = parameters[k].parameterName;
                        filteredUrl = LDH.ReplaceAll(filteredUrl, "{" + paramName + "}", controlValue);
                    }
                }
            }
            return filteredUrl;
        } catch (ex) {
            console.log("Failed to call GetFilteredUrlByDataViewParameters.");
            return originalUrl;
        }
    }

    static GetTableAndColumnNameByCustomQueryTableSchema(gridDefinition, tableName, columnName) {
        if (LDH.IsObjectNull(gridDefinition.dataSourceCustomQueryTableSchema) === false &&
            LDH.IsValueEmpty(gridDefinition.dataSourceCustomQueryTableSchema) === false &&
            LDH.IsValueEmpty(columnName) === false) {
            let tableSchemas = gridDefinition.dataSourceCustomQueryTableSchema.split("|");
            for (let m = 0; m < tableSchemas.length; m++) {
                if (columnName.indexOf("#") === -1 && tableSchemas[m].indexOf("{") === -1 &&
                    tableSchemas[m].indexOf("}") === -1) {
                    tableName = tableSchemas[m].trim();
                    break;
                }
                let cName = "{" + columnName.split("#")[0] + "}";
                if (columnName.indexOf("#") > -1 && tableSchemas[m].indexOf(cName) > -1) {
                    let startIndex = tableSchemas[m].indexOf("}") + 1;
                    let endIndex = tableSchemas[m].length;
                    tableName = tableSchemas[m].substring(startIndex, endIndex);
                    columnName = columnName.split("#")[1];
                    break;
                }
            }
        }
        return {tableName, columnName};
    }

    static EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, dataViewId, additionalData) {
        try {
            let resultValue = "";
            let guid = LDH.GenerateGuid();
            if (LDH.IsObjectNull(dataViewId) || LDH.IsValueEmpty(dataViewId)) {
                dataViewId = "";
            }
            if (LDH.IsObjectNull(additionalData)) additionalData = "";

            if (LeopardPermissionHelper.IsDebugModeEnabled()) {
                return "";
            }

            // --------------------------------------------
            // This code is required for eval()
            let momenttz = require("moment-timezone");
            let moment = require("moment");
            let $ = require("jquery");
            let SparkMD5 = require("spark-md5");

            // This code is for backwards compatibility.
            let _momenttz = require("moment-timezone");
            let _moment = require("moment");
            //---------------------------------------------

            let script = "window['result_" + guid + "']=null; " +
                "var custom=" + JSON.stringify(additionalData) + ";" +
                "var function_" + guid + "=function(){" +
                "var " + dataName + "=" + JSON.stringify(dataValue) + "; " +
                "var dataViewId=" + JSON.stringify(dataViewId) + "; " +
                javascript + ";" +
                "return " + dataName + ";};" +
                "window['result_" + guid + "'] = function_" + guid + "();";
            eval(script);
            resultValue = LDH.DeepClone(window["result_" + guid]);
            delete window["result_" + guid];

            if (resultValue !== null && typeof resultValue === "object" &&
                typeof resultValue.errorMessage !== "undefined") {
                LDH.ShowToast(resultValue.errorMessage, "error", 5000);
                return resultValue.result;
            }
            return resultValue;
        } catch (ex) {
            console.log("Failed to execute JavaScript.", javascript);
            return "";
        }
    }

    static ShowToast = (text, type, displayTime) => {
        notify(text, type, displayTime);
    };

    static ConvertToDocumentEditorCompatibleJSON(jsonData, isCloned) {
        if (isCloned === undefined) isCloned = false;
        if (LDH.IsObjectNull(jsonData)) return jsonData;
        let clonedJsonData = isCloned ? jsonData : LDH.DeepClone(jsonData);
        for (let key in clonedJsonData) {
            if (clonedJsonData.hasOwnProperty(key)) {
                if (key === "type") {
                    clonedJsonData["type_"] = clonedJsonData[key];
                    delete clonedJsonData[key];
                }
                if (typeof clonedJsonData[key] === 'object') {
                    LDH.ConvertToDocumentEditorCompatibleJSON(clonedJsonData[key], true);
                }
            }
        }
        return clonedJsonData;
    }

    static ConvertJsonDataNullOrEmptyValues(jsonData, isCloned) {
        if (isCloned === undefined) isCloned = false;
        if (LDH.IsObjectNull(jsonData)) return jsonData;
        let clonedJsonData = isCloned ? jsonData : LDH.DeepClone(jsonData);
        for (let key in clonedJsonData) {
            if (clonedJsonData.hasOwnProperty(key)) {
                if (clonedJsonData[key] === undefined) {
                    clonedJsonData[key] = "[UNDEFINED]";
                } else if (LDH.IsObjectNull(clonedJsonData[key])) {
                    clonedJsonData[key] = "[NULL]";
                } else if (clonedJsonData[key] === "") {
                    clonedJsonData[key] = "[EMPTY]";
                }
                if (typeof clonedJsonData[key] === 'object') {
                    LDH.ConvertJsonDataNullOrEmptyValues(clonedJsonData[key], true);
                }
            }
        }
        return clonedJsonData;
    }

    static FilterMacroForJsonValues(jsonData) {
        if (LDH.IsObjectNull(jsonData)) return jsonData;
        for (let key in jsonData) {
            if (jsonData[key] === null) {
                jsonData[key] = "";
            }
            if (jsonData.hasOwnProperty(key)) {
                if (jsonData[key].toString().indexOf("{") > -1 &&
                    jsonData[key].toString().indexOf("}") > -1) {
                    let value = LDH.FilterMacro(jsonData[key], 1, 3);
                    jsonData[key] = value;
                }
                if (typeof jsonData[key] === 'object') {
                    jsonData[key] = LDH.FilterMacroForJsonValues(jsonData[key]);
                }
            }
        }
        return jsonData;
    }

    static ConvertJsonDataNullValuesOnly(jsonData, isCloned) {
        if (isCloned === undefined) isCloned = false;
        if (LDH.IsObjectNull(jsonData)) return jsonData;
        let clonedJsonData = isCloned ? jsonData : LDH.DeepClone(jsonData);
        for (let key in clonedJsonData) {
            if (clonedJsonData.hasOwnProperty(key)) {
                if (clonedJsonData[key] === undefined) {
                    clonedJsonData[key] = "[UNDEFINED]";
                } else if (LDH.IsObjectNull(clonedJsonData[key])) {
                    clonedJsonData[key] = "[NULL]";
                }
                if (typeof clonedJsonData[key] === 'object') {
                    LDH.ConvertJsonDataNullValuesOnly(clonedJsonData[key], true);
                }
            }
        }
        return clonedJsonData;
    }

    static UnconvertJsonDataNullOrEmptyValues(jsonData, isCloned) {
        if (isCloned === undefined) isCloned = false;
        let clonedJsonData = isCloned ? jsonData : LDH.DeepClone(jsonData);
        for (let key in clonedJsonData) {
            if (clonedJsonData.hasOwnProperty(key)) {
                if (clonedJsonData[key] === "[UNDEFINED]") {
                    clonedJsonData[key] = undefined;
                } else if (clonedJsonData[key] === "[NULL]") {
                    clonedJsonData[key] = null;
                } else if (clonedJsonData[key] === "[EMPTY]") {
                    clonedJsonData[key] = "";
                }
                if (typeof clonedJsonData[key] === 'object') {
                    LDH.UnconvertJsonDataNullOrEmptyValues(clonedJsonData[key], true);
                }
            }
        }
        return clonedJsonData;
    }

    static IsValueTrue(value) {
        if (LDH.IsObjectNull(value) || value === "") {
            return false;
        }
        return value.toString().toLowerCase() === "true";
    }

    static ConvertGuidToUpperCaseFromJSON(jsonData) {
        let keys = Object.keys(jsonData);
        for (let i = 0; i < keys.length; i++) {
            let value = jsonData[keys[i]];
            if (!LDH.IsObjectNull(LeopardStaticUIConfig.GuidRegEx.exec(value))) {
                jsonData[keys[i]] = value.toString().toUpperCase();
            }
        }
        return jsonData;
    }

    static GetCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static ConvertDateToYYYYMMDD = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    };

    static ConvertDateToDDMMYYYY = (date, splitter) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [day, month, year].join(splitter);
    };

    static DeepClone(obj) {
        if (this.IsObjectNull(obj)) return null;
        return JSON.parse(JSON.stringify(obj));
    }

    static GenerateGuid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }

    static IsArrayContainDuplicates(arry, columnId) {
        let filteredList = [];
        for (let i = 0; i < arry.length; i++) {
            let item = arry[i];
            for (let j = 0; j < filteredList.length; j++) {
                let filteredItem = filteredList[j];
                if (filteredItem[columnId] === item[columnId]) {
                    return true;
                }
            }
            filteredList.push(item);
        }
        return false;
    }

    static RemoveDuplicatesFromArray(arry, columnId) {
        let filteredList = [];
        for (let i = 0; i < arry.length; i++) {
            let item = arry[i];
            let foundDuplicate = false;
            for (let j = 0; j < filteredList.length; j++) {
                let filteredItem = filteredList[j];
                if (filteredItem[columnId] === item[columnId]) {
                    foundDuplicate = true;
                    break;
                }
            }
            if (!foundDuplicate) filteredList.push(item);
        }
        return filteredList;
    }

    static ConvertUTCDateToLocalDate(dateObj) {
        if (isNaN(dateObj)) {
            dateObj = new Date();
        }
        let offset = dateObj.getTimezoneOffset() / 60;
        let hours = dateObj.getHours();
        dateObj.setHours(hours - offset);
        return dateObj;
    }

    static ConvertLocalDateToUTCDate(dateObj) {
        if (isNaN(dateObj)) {
            dateObj = new Date();
        }
        let result = new Date(dateObj.toUTCString().slice(0, -3));
        result.convertedToUtc = true;
        return result;
    }

    static GetLocalISODateString(localDate) {
        if (isNaN(localDate)) {
            localDate = new Date();
        }
        let tzoffset = localDate.getTimezoneOffset() * 60000;
        let localISOTime = (new Date(localDate - tzoffset)).toISOString().slice(0, -1);
        return localISOTime + "Z";
    }

    static GenerateGuidWithDashes() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        return (s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()).toUpperCase();
    }

    static IsIsoDateFormat(str) {
        try {
            if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
                return false;
            }
            let d = new Date(str);
            return d.toISOString() === str;
        } catch (error) {
            return false;
        }
    }

    static TraverseJSONObject(jsonObj) {
        if (!LDH.IsObjectNull(jsonObj) && typeof jsonObj === "object") {
            Object.entries(jsonObj).forEach(([key, value]) => {
                jsonObj[key] = LDH.TraverseJSONObject(value);
            });
            return jsonObj
        } else {
            try {
                let date = new Date(jsonObj);
                if (!LDH.IsValueEmpty(date) &&
                    LDH.IsIsoDateFormat(jsonObj)) {
                    jsonObj = date;
                }
            } catch (error) {
                return jsonObj
            }
        }
        return jsonObj
    }

    static ParseDevExtremeFilterString(filterValue) {
        if (this.IsObjectNull(filterValue)) return null;
        let clonedValue = LDH.DeepClone(filterValue);
        try {
            return LDH.TraverseJSONObject(filterValue);
        } catch (error) {
            return clonedValue;
        }
    }

    static AddLeadingZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    static ValidateRegEx(regex, data) {
        try {
            if (regex === "[required]") {
                return !LDH.IsValueEmpty(data);
            }
            let re = new RegExp(regex);
            return re.test(data);
        } catch (error) {
            return false;
        }
    }

    static ConvertCsvToJSON(csv) {
        let lines = csv.split("\n");
        let result = [];
        let headers = lines[0].split(",");
        for (let i = 1; i < lines.length - 1; i++) {
            let obj = {};
            let currentline = lines[i].split(",");
            for (let j = 0; j < headers.length; j++) {
                if (LDH.IsValueEmpty(headers[j])) {
                    continue;
                }
                obj[headers[j].trim()] = currentline[j].trim();
            }
            result.push(obj);
        }
        return result;
    }

    static ConvertCsvToJSONWithoutQuotes(csv, delimiter) {
        let lines = csv.split("\n");
        let result = [];
        let headers = lines[0].split(delimiter);

        for (let i = 1; i < lines.length - 1; i++) {
            let obj = {};
            let currentline = lines[i].split(delimiter);

            for (let j = 0; j < headers.length; j++) {
                if (typeof headers[j] === "undefined" ||
                    headers[j] === null) {
                    continue;
                }
                let v = currentline[j].replace(/"/g, '').trim();
                obj[headers[j].replace(/"/g, '').trim()] = v;
            }
            result.push(obj);
        }
        return result;
    };

    static ReadTextFile = function (e, callback) {
        try {
            let input = e.target;
            let reader = new FileReader();
            let content = "";

            reader.onload = function () {
                content = reader.result;
                return callback(content);
            };
            if (LDH.IsObjectNull(input.files) ||
                input.files.length === 0) {
                return;
            }
            reader.readAsText(input.files[0]);
        } catch (error) {

        }
    };

    static GetClientSideQuery(query, variableName) {
        let id = LDH.GenerateGuid();
        return "var val_" + id + "=" + variableName + ";try{" + query + "val_" + id + "=null}" +
            "catch(error){" + variableName + " = val_" + id + ";window.__callback();}";
    }

    static GetClientSideQueryWithIdentifier(query, variableName, identifier) {
        let id = LDH.GenerateGuid();
        return "var val_" + id + "=" + variableName + ";" +
            "var identifier = '" + identifier + "';" +
            "try{" + query + "val_" + id + "=null}" +
            "catch(error){" + variableName + " = val_" + id + ";window.__callback();}";
    }

    static IsTimeoutReceivedFromAPIGateway(data) {
        let errorTexts = ["status code 504", "ETIMEDOUT", "timed out", "server error"];
        let errorMessage = "";

        if (!LDH.IsObjectNull(data) && typeof data === "object" &&
            !LDH.IsObjectNull(data.errorMessage) && !LDH.IsValueEmpty(data.errorMessage) &&
            typeof data.errorMessage !== "object") {
            errorMessage = data.errorMessage;
        } else if (!LDH.IsObjectNull(data) && typeof data === "object" &&
            !LDH.IsObjectNull(data.response) && typeof data.response === "object" &&
            !LDH.IsObjectNull(data.response.data) &&
            typeof data.response.data === "object" &&
            !LDH.IsObjectNull(data.response.data.message) &&
            !LDH.IsValueEmpty(data.response.data.message) &&
            typeof data.response.data.message !== "object") {
            errorMessage = data.response.data.message;
        }

        if (LDH.IsValueEmpty(errorMessage) === false) {
            for (let i = 0; i < errorTexts.length; i++) {
                if (errorMessage.indexOf(errorTexts[i]) !== -1) {
                    return true;
                }
            }
        }
        if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data.error) &&
            typeof data.error === "object" &&
            !LDH.IsObjectNull(data.error.httpStatus) &&
            data.error.httpStatus === 504) {
            return true;
        }
        if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data.response) &&
            typeof data.response === "object" &&
            !LDH.IsObjectNull(data.response.status) &&
            data.response.status === 504) {
            return true;
        }
        return false;
    }

    static GetTodayUtc() {
        let today = new Date();
        return (new Date(today.getFullYear(), today.getMonth(), today.getDate())).toISOString();
    }

    static GetTodayLocal() {
        return moment().format('YYYY-MM-DD') + " 00:00:00";
    }

    static GetNowLocal() {
        return moment().format('YYYY-MM-DD HH:mm:ss');
    }

    static GetNowLocalString() {
        return moment().format('YYYYMMDDHHmmss');
    }

    static GetNowUtc() {
        let today = new Date();
        return today.toISOString();
    }

    static IsJsonFormat(data) {
        try {
            if (typeof data === "object") {
                return true;
            }
            let dataJSON = JSON.parse(data);
            return dataJSON !== null;
        } catch (ex) {
            return false;
        }
    }

    static GetIncludedColumns(limitedDataColumns, fullColumns, isJSONDataFormat) {
        let filterColumns = [];
        let fullColumnsWithoutCustom = [];
        if (LDH.IsObjectNull(fullColumns)) fullColumns = [];

        for (let i = 0; i < fullColumns.length; i++) {
            if (LDH.IsObjectNull(isJSONDataFormat) || LDH.IsValueEmpty(isJSONDataFormat) || !isJSONDataFormat) {
                if (fullColumns[i].startsWith("_")) {
                    continue;
                }
            }
            fullColumnsWithoutCustom.push(fullColumns[i]);
        }

        if (LDH.IsObjectNull(limitedDataColumns) || limitedDataColumns.length === 0) {
            return fullColumnsWithoutCustom;
        }
        if (!LDH.IsObjectNull(fullColumnsWithoutCustom) && fullColumnsWithoutCustom.length > 0) {
            for (let i = 0; i < fullColumnsWithoutCustom.length; i++) {
                let found = false;
                for (let j = 0; j < limitedDataColumns.length; j++) {
                    if (fullColumnsWithoutCustom[i] === limitedDataColumns[j]) {
                        found = true;
                        break;
                    }
                }
                if (found === false) {
                    filterColumns.push(fullColumnsWithoutCustom[i]);
                }
            }
        }
        return filterColumns;
    }

    static AddHoursToDateUtc(isoDate, hours) {
        let result = new Date(isoDate);
        result.setHours(result.getHours() + hours);
        return result.toISOString();
    }

    static AddMonthsToDate(dateObj, months) {
        let tempDate = dateObj.getDate();
        dateObj.setMonth(dateObj.getMonth() + +months);
        if (dateObj.getDate() !== tempDate) {
            dateObj.setDate(0);
        }
        return dateObj;
    }

    static AddHoursToDateLocal(localDate, hours) {
        let result = new Date(localDate);
        result.setHours(result.getHours() + hours);

        let datePart = LDH.ConvertDateToYYYYMMDD(result);
        let hour = result.getHours();
        if (hour < 10) hour = "0" + hour;
        return datePart + "T" + hour + ":00:00";
    }

    static GenerateRandomNumber(minValue, maxValue) {
        return Math.floor((Math.random() * maxValue) + minValue);
    }

    static TruncateDataForGridView(gridDefinition, data) {
        for (let i = 0; i < gridDefinition.columnDefinition.length; i++) {
            let column = gridDefinition.columnDefinition[i];
            let columnName = column.columnName;
            let fieldSuffix = "___Untruncated";
            let ellipsesSymbol = "…";
            let defaultTruncateMethod = LeopardDropdownHelper.DropdownSelectionTruncationMethod[0].id;
            for (let j = 0; j < data.length; j++) {
                if (!LDH.IsObjectNull(data[j][columnName]) && !LDH.IsValueEmpty(data[j][columnName]) &&
                    !LDH.IsObjectNull(column.truncationMethod) && column.truncationMethod !== defaultTruncateMethod &&
                    !LDH.IsObjectNull(column.truncationLimit) && !LDH.IsValueEmpty(column.truncationLimit)) {
                    let truncationLimit = column.truncationLimit;
                    if (column.truncationMethod === LeopardDropdownHelper.DropdownSelectionTruncationMethod[1].id) {
                        data[j][columnName + fieldSuffix] = data[j][columnName];

                        let truncatedValue = data[j][columnName].toString().substring(0, truncationLimit);
                        if (truncatedValue !== data[j][columnName]) truncatedValue += ellipsesSymbol;
                        data[j][columnName] = truncatedValue;
                    } else if (column.truncationMethod === LeopardDropdownHelper.DropdownSelectionTruncationMethod[2].id) {
                        let startIndex = data[j][columnName].toString().length - truncationLimit;
                        let endIndex = data[j][columnName].toString().length;
                        data[j][columnName + fieldSuffix] = data[j][columnName];

                        let truncatedValue = data[j][columnName].toString().substring(startIndex, endIndex);
                        if (truncatedValue !== data[j][columnName]) truncatedValue = ellipsesSymbol + truncatedValue;
                        data[j][columnName] = truncatedValue;
                    } else if (column.truncationMethod === LeopardDropdownHelper.DropdownSelectionTruncationMethod[3].id) {
                        let endIndex = data[j][columnName].toString().length;
                        data[j][columnName + fieldSuffix] = data[j][columnName];

                        let truncatedValue = data[j][columnName].toString().substring(truncationLimit, endIndex);
                        if (truncatedValue !== data[j][columnName]) truncatedValue = ellipsesSymbol + truncatedValue;
                        data[j][columnName] = truncatedValue;
                    } else if (column.truncationMethod === LeopardDropdownHelper.DropdownSelectionTruncationMethod[4].id) {
                        let endIndex = data[j][columnName].toString().length - truncationLimit;
                        data[j][columnName + fieldSuffix] = data[j][columnName];

                        let truncatedValue = data[j][columnName].toString().substring(0, endIndex);
                        if (truncatedValue !== data[j][columnName]) truncatedValue += ellipsesSymbol;
                        data[j][columnName] = truncatedValue;
                    }
                }
            }
        }
        return data;
    }

    static FilterMacro(dataString, loopValueGrid, loopValueDate) {
        let today = LDH.GetTodayUtc();
        let now = LDH.GetNowUtc();
        let nowLocal = LDH.GetNowLocal();
        let todayLocal = LDH.GetTodayLocal();

        if (LDH.IsValueEmpty(dataString)) dataString = "";
        dataString = dataString.toString();

        let keys = Object.keys(window);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i].indexOf("globalvar_") === -1) {
                continue;
            }
            if (typeof window[keys[i]] === "undefined" || window[keys[i]] === null) {
                continue;
            }
            dataString = LDH.ReplaceAll(dataString, "{" + keys[i] + "}", window[keys[i]]);
        }

        dataString = LDH.ReplaceAll(dataString, "{user-group-id}", window.userProfile.Parent.toLowerCase());
        dataString = LDH.ReplaceAll(dataString, "{user-group-name}", window.userProfile.ParentGroup.Name);
        dataString = LDH.ReplaceAll(dataString, "{user-name}", window.userProfile.Name);
        dataString = LDH.ReplaceAll(dataString, "{user-first-name}", window.userProfile.FirstName);
        dataString = LDH.ReplaceAll(dataString, "{user-last-name}", window.userProfile.LastName);
        dataString = LDH.ReplaceAll(dataString, "{user-id}", window.userProfile.ID);
        dataString = LDH.ReplaceAll(dataString, "{today}", today);
        dataString = LDH.ReplaceAll(dataString, "{isodate-now}", now);
        dataString = LDH.ReplaceAll(dataString, "{now}", nowLocal);
        dataString = LDH.ReplaceAll(dataString, "{today-local}", todayLocal);
        dataString = LDH.ReplaceAll(dataString, "{yesterday}", "{day(-1)}");
        dataString = LDH.ReplaceAll(dataString, "{tomorrow}", "{day(1)}");

        dataString = LDH.ReplaceAll(dataString, "{next3days}", "{day(3)}");
        dataString = LDH.ReplaceAll(dataString, "{next7days}", "{day(7)}");
        dataString = LDH.ReplaceAll(dataString, "{next14days}", "{day(14)}");
        dataString = LDH.ReplaceAll(dataString, "{next30days}", "{day(30)}");

        dataString = LDH.ReplaceAll(dataString, "{last3days}", "{day(-3)}");
        dataString = LDH.ReplaceAll(dataString, "{last7days}", "{day(-7)}");
        dataString = LDH.ReplaceAll(dataString, "{last14days}", "{day(-14)}");
        dataString = LDH.ReplaceAll(dataString, "{last30days}", "{day(-30)}");

        let loopValueForGuid = 5;
        if (!LDH.IsObjectNull(loopValueGrid) && !LDH.IsValueEmpty(loopValueGrid)) {
            loopValueForGuid = loopValueGrid;
        }
        for (let i = 0; i < loopValueForGuid; i++) {
            dataString = dataString.replace("{random-guid}", LDH.GenerateGuidWithDashes().toLowerCase());
        }

        let loopValueForDate = 1000;
        if (!LDH.IsObjectNull(loopValueDate) && !LDH.IsValueEmpty(loopValueDate)) {
            loopValueForDate = loopValueDate;
        }

        for (let i = -loopValueForDate; i < loopValueForDate; i++) {
            dataString = LDH.DateMacroConverter(dataString, i);
        }
        dataString = LDH.DateMacroConverter(dataString, "-0");

        return dataString;
    }

    static GetImageListFromResult(result, itemId) {
        let items = [];
        if (!LDH.IsObjectNull(result) && !LDH.IsObjectNull(result.message) &&
            result.message.length > 0) {
            for (let i = 0; i < result.message.length; i++) {
                if (result.message[i].DisplayType === "TN" &&
                    (result.message[i].ContentType === "image/jpeg" ||
                        result.message[i].ContentType === "image/svg+xml")) {
                    let originalImageUrl = "";

                    for (let j = 0; j < result.message.length; j++) {
                        if (result.message[j].FileName === result.message[i].FileName &&
                            result.message[j].DisplayType === "IMG" &&
                            (result.message[i].ContentType === "image/jpeg" ||
                                result.message[i].ContentType === "image/svg+xml")) {
                            originalImageUrl = result.message[j].Url;
                            break;
                        }
                    }

                    items.push({
                        imageUrl: result.message[i].Url,
                        id: LDH.GenerateGuid(),
                        itemId,
                        originalImageUrl
                    });
                }
            }
        }
        return items;
    }

    static ParseGuidForODataQuery(dataString, columnName) {
        let guidMatches = [];
        let match;
        while ((match = !LDH.IsObjectNull(LeopardStaticUIConfig.GuidRegEx.exec(dataString)))) {
            guidMatches.push(match[0]);
        }
        for (let i = 0; i < guidMatches.length; i++) {
            let value = guidMatches[i];
            dataString = LDH.ReplaceAll(dataString, "'" + value + "'", value);
            dataString = LDH.ReplaceAll(dataString, value, "'" + value + "'");

            if (!LDH.IsObjectNull(columnName) && !LDH.IsValueEmpty(columnName)) {
                dataString = LDH.ReplaceAll(dataString, columnName + " eq '" + value + "'",
                    "(startswith(tolower(" + columnName + "),'" + value + "'))");
            }
        }
        return dataString;
    }

    static DateMacroConverter(dataString, index) {
        let today = LDH.GetTodayUtc();
        let todayLocal = LDH.GetTodayLocal();

        if (dataString.indexOf("{day(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateUtc(today, index * 24);
            dataString = LDH.ReplaceAll(dataString, "{day(" + index + ")}", result);
        }
        if (dataString.indexOf("{day-local(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateLocal(todayLocal, index * 24);
            dataString = LDH.ReplaceAll(dataString, "{day-local(" + index + ")}", result);
        }
        if (dataString.indexOf("{hour(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateUtc(today, index);
            dataString = LDH.ReplaceAll(dataString, "{hour(" + index + ")}", result);
        }
        if (dataString.indexOf("{hour-local(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateLocal(todayLocal, index);
            dataString = LDH.ReplaceAll(dataString, "{hour-local(" + index + ")}", result);
        }
        if (dataString.indexOf("{day-us(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateUtc(today, index * 24);
            result = LDH.ConvertDateToYYYYMMDD(result);
            dataString = LDH.ReplaceAll(dataString, "{day-us(" + index + ")}", result);
        }
        if (dataString.indexOf("{hour-us(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateUtc(today, index);
            result = LDH.ConvertDateToYYYYMMDD(result);
            dataString = LDH.ReplaceAll(dataString, "{hour-us(" + index + ")}", result);
        }
        if (dataString.indexOf("{firstday-month-start(" + index + ")}") > -1) {
            let dateObj = new Date();
            let firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + index, 1);
            let result = LDH.AddHoursToDateUtc(firstDay, 0);
            dataString = LDH.ReplaceAll(dataString, "{firstday-month-start(" + index + ")}", result);
        }
        if (dataString.indexOf("{firstday-month-end(" + index + ")}") > -1) {
            let dateObj = new Date();
            let firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + index, 1, 23, 59, 59, 999);
            let result = LDH.AddHoursToDateUtc(firstDay, 0);
            dataString = LDH.ReplaceAll(dataString, "{firstday-month-end(" + index + ")}", result);
        }
        if (dataString.indexOf("{lastday-month-start(" + index + ")}") > -1) {
            let dateObj = new Date();
            let lastDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1 + index, 0);
            let result = LDH.AddHoursToDateUtc(lastDay, 0);
            dataString = LDH.ReplaceAll(dataString, "{lastday-month-start(" + index + ")}", result);
        }
        if (dataString.indexOf("{lastday-month-end(" + index + ")}") > -1) {
            let dateObj = new Date();
            let lastDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1 + index, 0, 23, 59, 59, 999);
            let result = LDH.AddHoursToDateUtc(lastDay, 0);
            dataString = LDH.ReplaceAll(dataString, "{lastday-month-end(" + index + ")}", result);
        }
        return dataString;
    }

    static ConvertArrayMacroToString(dataString, arrayData, columnDefinition) {
        if (LDH.IsObjectNull(arrayData)) return dataString;

        let columns = Object.keys(arrayData);
        for (let i = 0; i < columns.length; i++) {
            let name = columns[i];
            let value = arrayData[name];

            if (LDH.IsObjectNull(columnDefinition) === false) {
                for (let j = 0; j < columnDefinition.length; j++) {
                    if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                            columnDefinition[j].columnType === "string") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = '';
                    } else if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                            columnDefinition[j].columnType === "guid") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = '';
                    } else if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                            columnDefinition[j].columnType === "datetime") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = this.GetTodayUtc();
                    } else if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                            columnDefinition[j].columnType === "date") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = this.GetTodayUtc();
                    } else if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                            columnDefinition[j].columnType === "number") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = 0;
                    } else if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                            columnDefinition[j].columnType === "boolean") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = 0;
                    }
                }
            }
            if (LDH.IsValueEmpty(value)) value = '';
            dataString = LDH.ReplaceAll(dataString, "{" + name + "}", value);
        }
        return dataString;
    }

    static GetOrganizationIdFromUserProfile(userProfile) {
        if (LDH.IsObjectNull(userProfile)) {
            return null;
        }
        if (LDH.IsObjectNull(userProfile.data)) {
            if (!LDH.IsObjectNull(userProfile.Parent)) {
                return userProfile.Parent;
            }
            return null;
        }
        return userProfile.data.Parent;
    }

    static GetUserIdFromUserProfile(userProfile) {
        if (LDH.IsObjectNull(userProfile)) {
            return null;
        }
        if (LDH.IsObjectNull(userProfile.data)) {
            if (!LDH.IsObjectNull(userProfile.ID)) {
                return userProfile.ID;
            }
            return null;
        }
        return userProfile.data.ID;
    }

    static IsControlCentreLatestVersion(data) {
        try {
            let ccVersion = LeopardStaticUIConfig.ControlCentreVersion;
            if (ccVersion.indexOf("BUILD_NUMBER_PLACEHOLDER") > -1) {
                return true;
            }

            let currentVersion = data;
            if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data.currentVersion) &&
                typeof data.currentVersion === "string") {
                currentVersion = data.currentVersion;
            }
            if (LDH.IsValueEmpty(currentVersion) || typeof currentVersion !== "string") {
                return true;
            }
            let remoteVersion = parseInt(LDH.ReplaceAll(currentVersion, ".", ""));
            let localVersion = ccVersion;

            localVersion = parseInt(LDH.ReplaceAll(localVersion, ".", ""));
            return localVersion >= remoteVersion;
        } catch (ex) {
            return true;
        }
    }

    static HexToBin(hex) {
        var ret = [];
        var i = 0;
        var l;
        hex += '';
        for (l = hex.length; i < l; i += 2) {
            var c = parseInt(hex.substr(i, 1), 16);
            var k = parseInt(hex.substr(i + 1, 1), 16);
            if (isNaN(c) || isNaN(k)) return false;
            ret.push((c << 4) | k);
        }
        return ret;
    }

    static IsUpdateControlCentreVersionRequired(data) {
        let isRequired;
        let ccVersion = LeopardStaticUIConfig.ControlCentreVersion;

        if ((LDH.IsObjectNull(data) || data.length === 0) &&
            ccVersion.indexOf("BUILD_NUMBER_PLACEHOLDER") === -1) {
            isRequired = true;
        } else if (ccVersion.indexOf("BUILD_NUMBER_PLACEHOLDER") > -1) {
            isRequired = false;
        } else {
            let remoteVersion = parseInt(LDH.ReplaceAll(data.currentVersion, ".", ""));
            let localVersion = ccVersion;
            localVersion = parseInt(LDH.ReplaceAll(localVersion, ".", ""));
            isRequired = localVersion > remoteVersion;
        }
        return isRequired;
    }

    static SimplifyReportArgs(reportArgs, timezoneName) {
        let newReportArgs = {};
        for (let j = 0; j < reportArgs.length; j++) {
            let value1 = reportArgs[j].Value;
            if (reportArgs[j].Value2 !== null && reportArgs[j].Value2 !== "") {
                let value2 = reportArgs[j].Value2;
                value1 = "{" + LDH.ReplaceAll(value1, "(NNN)", "(" + value2 + ")") + "}";

                let finalValue = LDH.FilterMacro(value1, timezoneName);
                newReportArgs[reportArgs[j].Name] = finalValue;
            } else {
                newReportArgs[reportArgs[j].Name] = value1;
            }
        }
        return newReportArgs;
    };

    static GetRelationshipsByDashboardItemId = (relationships, dashboardItemId) => {
        let resultList = [];
        for (let i = 0; i < relationships.length; i++) {
            if (relationships[i].parentDataViewId.split(":")[0] === dashboardItemId) {
                resultList.push(relationships[i]);
            }
            if (relationships[i].childDataViewId.split(":")[0] === dashboardItemId) {
                resultList.push(relationships[i]);
            }
        }
        return resultList;
    };

    static ValidateDashboardRelationships = (relationships, requestPId, requestCId, requestRId) => {
        let parentRIds = [];
        if (LDH.IsValueEmpty(requestPId) || LDH.IsValueEmpty(requestCId)) return true;
        if (requestPId === requestCId) return false;

        for (let w = 0; w < relationships.length; w++) {
            if (relationships[w].relationshipId === requestRId) {
                relationships[w].parentDataViewId = requestPId;
                relationships[w].childDataViewId = requestCId;
            }
        }

        for (let i = 0; i < relationships.length; i++) {
            if (relationships[i].childDataViewId === requestCId &&
                relationships[i].relationshipId !== requestRId) {
                return false;
            }

            if (relationships[i].childDataViewId === requestCId &&
                relationships[i].parentDataViewId === requestPId &&
                requestRId !== relationships[i].relationshipId) {
                return false;
            }

            for (let j = 0; j < relationships.length; j++) {
                if (relationships[i].childDataViewId === relationships[j].parentDataViewId &&
                    !LDH.IsValueEmpty(relationships[j].parentDataViewId)) {

                    let foundParent = false;
                    for (let m = 0; m < parentRIds.length; m++) {
                        if (parentRIds[m] === relationships[i].relationshipId) {
                            foundParent = true;
                        }
                    }
                    if (foundParent === false) {
                        parentRIds.push(relationships[i].relationshipId);
                    }

                    let result = this.FindDashboardRelationshipItem(relationships, requestPId,
                        requestCId, requestRId, relationships[j].relationshipId,
                        parentRIds);

                    if (result === "not-found") {
                        parentRIds = [];
                    } else {
                        return result;
                    }
                }
            }
        }
        return true;
    };

    static FindDashboardRelationshipItem = (relationships, requestPId, requestCId,
                                            requestRId, currentRId, parentRIds) => {
        let currentChildDataViewId = "";
        if (currentRId === requestRId) {
            currentChildDataViewId = requestCId;
        } else {
            for (let i = 0; i < relationships.length; i++) {
                if (relationships[i].relationshipId === currentRId) {
                    currentChildDataViewId = relationships[i].childDataViewId;
                }
            }
        }

        for (let k = 0; k < parentRIds.length; k++) {
            for (let n = 0; n < relationships.length; n++) {
                if (relationships[n].relationshipId === parentRIds[k] &&
                    relationships[n].parentDataViewId === currentChildDataViewId) {
                    return false;
                }
            }
        }

        for (let w = 0; w < relationships.length; w++) {
            if (relationships[w].childDataViewId === currentChildDataViewId &&
                relationships[w].relationshipId !== currentRId) {
                return false;
            }
        }

        for (let j = 0; j < relationships.length; j++) {
            if (currentChildDataViewId === relationships[j].parentDataViewId &&
                !LDH.IsValueEmpty(relationships[j].parentDataViewId)) {
                parentRIds.push(currentRId);

                return this.FindDashboardRelationshipItem(relationships, requestPId,
                    requestCId, requestRId, relationships[j].relationshipId,
                    parentRIds);
            }
        }
        return "not-found";
    };

    static ConvertImageToBase64 = (src, format) => {
        try {
            let base64 = new Buffer(src).toString('base64');
            let dataFormat = "jpg";
            format = format.toLowerCase();

            if (format === "svg") dataFormat = "svg+xml";
            if (format === "gif") dataFormat = "gif";
            if (format === "jpg") dataFormat = "jpg";
            if (format === "jpeg") dataFormat = "jpg";
            if (format === "png") dataFormat = "png";
            return "data:image/" + dataFormat + ";base64," + base64;
        } catch (ex) {
            return "";
        }
    };

    static FindMenuItemNodesByText = (arr, searchText) => {
        let result = [];
        function search(node) {
            if (node.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
                result.push(node);
            }
            if (node.children && node.children.length > 0) {
                node.children.forEach(child => search(child));
            }
        }
        arr.forEach(item => search(item));
        return result;
    };

    static ConvertStringToBase64 = (str) => {
        if (LDH.IsValueEmpty(str)) return "";
        return btoa(str);
    };

    static ConvertBase64ToString = (str) => {
        if (LDH.IsValueEmpty(str)) return "";
        return atob(str);
    };

    static GetFileExtensionFromString = (str) => {
        if (str === null || str === "") return "";
        return str.substring(str.lastIndexOf(".") + 1);
    };

    static SetRelationshipColorBar = (relationships) => {
        if (LDH.IsObjectNull(relationships)) return [];
        let colorBars = LeopardStaticUIConfig.LeopardDashboardColorBars;
        let colorIndex = 0;
        let coloredParentDataViewIds = [];

        for (let n = 0; n < relationships.length; n++) {
            if (LDH.IsValueEmpty(relationships[n].parentDataViewId) ||
                LDH.IsValueEmpty(relationships[n].childDataViewId)) {
                continue;
            }
            let foundColor = null;
            let foundLevel = null;
            let pId = relationships[n].parentDataViewId;

            for (let k = 0; k < coloredParentDataViewIds.length; k++) {
                if (coloredParentDataViewIds[k].pId === pId) {
                    foundColor = coloredParentDataViewIds[k].color;
                    foundLevel = coloredParentDataViewIds[k].level;
                    break;
                }
            }
            if (!LDH.IsObjectNull(foundColor)) {
                relationships[n].color = foundColor;
                relationships[n].level = foundLevel;
            }

            if ((LDH.IsObjectNull(relationships[n].color) ||
                    LDH.IsValueEmpty(relationships[n].color)) &&
                LDH.IsValueEmpty(foundColor)) {
                if (colorIndex > colorBars.length) {
                    colorIndex = 0;
                }

                let color = colorBars[colorIndex];
                let level = 1;

                for (let j = 0; j < relationships.length; j++) {
                    let cId = relationships[n].childDataViewId;
                    if (relationships[j].parentDataViewId === cId &&
                        !LDH.IsObjectNull(relationships[j].color) &&
                        !LDH.IsValueEmpty(relationships[j].color)) {
                        color = relationships[j].color;
                        break;
                    }
                }
                relationships[n].color = color;
                relationships[n].level = level;
                coloredParentDataViewIds.push({
                    pId, color, level
                });
                colorIndex++;
            }

            let rsId = relationships[n].relationshipId;
            LDH.SetRelationshipColorForChildren(rsId, relationships);
        }
        return relationships;
    };

    static SetRelationshipColorForChildren = (currentRelationshipId, relationships) => {
        for (let i = 0; i < relationships.length; i++) {
            if (currentRelationshipId !== relationships[i].relationshipId) {
                continue;
            }
            let childDataViewId = relationships[i].childDataViewId;

            for (let j = 0; j < relationships.length; j++) {
                if (relationships[j].parentDataViewId === childDataViewId) {
                    relationships[j].color = relationships[i].color;

                    relationships[j].level = LDH.IsObjectNull(relationships[i].level) ||
                    LDH.IsValueEmpty(relationships[i].level) ? 1 :
                        relationships[i].level + 1;

                    let rsId = relationships[j].relationshipId;
                    LDH.SetRelationshipColorForChildren(rsId, relationships);
                }
            }
        }
    };

    static SetPostProcessResultForDataViewInstance = (instance, isDataView, hasCustomQueryParams,
                                                      dashboardItemId) => {
        let relationships = instance.option("relationships");
        if (!isDataView && (LDH.IsObjectNull(instance.option("customQueryParams")) ||
                LDH.IsValueEmpty(instance.option("customQueryParams"))) &&
            hasCustomQueryParams) {
            if (!LDH.IsObjectNull(relationships) && relationships.length > 0) {
                let foundParent = false;
                for (let v = 0; v < relationships.length; v++) {
                    if (relationships[v].childDataViewId.split(":")[0] === dashboardItemId &&
                        relationships[v].parentDataViewId.split(":")[0] !== dashboardItemId) {
                        foundParent = true;
                    }
                }
                if (foundParent) instance.option("setBlankPostProcess", true);
            } else {
                instance.option("setBlankPostProcess", false);
            }
        }
    };

    static GetODataAPIGatewayUrl = (parameter) => {
        var gatewayUrl = "/reports/odata";
        if (LDH.IsValueEmpty(parameter)) {
            return gatewayUrl + parameter;
        }
        if (parameter.indexOf("view/") > -1) {
            gatewayUrl = "/reports";
        } else if (parameter.charAt(0) !== "/") {
            parameter = "/" + parameter;
        }
        return gatewayUrl + parameter;
    };

    static GetAthenaAPIGatewayUrl = (parameter) => {
        var gatewayUrl = "/reports/athena";
        if (LDH.IsValueEmpty(parameter)) {
            return gatewayUrl + parameter;
        }
        if (parameter.charAt(0) !== "/") {
            parameter = "/" + parameter;
        }
        return gatewayUrl + parameter;
    };

    static GetCustomDataSources(reportDef, args, keys, organizationId) {
        let queries = [];

        if (!LDH.IsObjectNull(reportDef.reportCustomDataSources)) {
            for (let v = 0; v < reportDef.reportCustomDataSources.length; v++) {
                let customQuery = reportDef.reportCustomDataSources[v].query;
                let dsName = reportDef.reportCustomDataSources[v].name;
                let customParentQuery1 = reportDef.reportCustomDataSources[v].parentQuery1;
                let customParentQuery2 = reportDef.reportCustomDataSources[v].parentQuery2;
                let customParentQuery3 = reportDef.reportCustomDataSources[v].parentQuery3;

                if (args != null && keys !== null) {
                    customQuery = LDH.GetSanitizedParameters(args, keys, customQuery, false);
                }
                customQuery = LDH.ReplaceAll(customQuery, "{Input_UserGroupId}", organizationId);
                customQuery = LDH.FilterMacro(customQuery);

                if (!LDH.IsObjectNull(customParentQuery1) && !LDH.IsValueEmpty(customParentQuery1)) {
                    if (args != null && keys !== null) {
                        customParentQuery1 = LDH.GetSanitizedParameters(args, keys, customParentQuery1, false);
                    }
                    customParentQuery1 = LDH.ReplaceAll(customParentQuery1, "{Input_UserGroupId}", organizationId);
                    customParentQuery1 = LDH.FilterMacro(customParentQuery1);
                }

                if (!LDH.IsObjectNull(customParentQuery2) && !LDH.IsValueEmpty(customParentQuery2)) {
                    if (args != null && keys !== null) {
                        customParentQuery2 = LDH.GetSanitizedParameters(args, keys, customParentQuery2, false);
                    }
                    customParentQuery2 = LDH.ReplaceAll(customParentQuery2, "{Input_UserGroupId}", organizationId);
                    customParentQuery2 = LDH.FilterMacro(customParentQuery2);
                }

                if (!LDH.IsObjectNull(customParentQuery3) && !LDH.IsValueEmpty(customParentQuery3)) {
                    if (args != null && keys !== null) {
                        customParentQuery3 = LDH.GetSanitizedParameters(args, keys, customParentQuery3, false);
                    }
                    customParentQuery3 = LDH.ReplaceAll(customParentQuery3, "{Input_UserGroupId}", organizationId);
                    customParentQuery3 = LDH.FilterMacro(customParentQuery3);
                }

                if (!LDH.IsObjectNull(reportDef.reportCustomDataSources[v].queryShaping) &&
                    !LDH.IsValueEmpty(reportDef.reportCustomDataSources[v].queryShaping)) {
                    try {
                        eval(reportDef.reportCustomDataSources[v].queryShaping);
                    } catch (ex) {
                        console.log("Failed to execute queryShaping.");
                    }
                }

                queries.push({
                    parentQuery1: customParentQuery1,
                    parentQuery2: customParentQuery2,
                    parentQuery3: customParentQuery3,
                    finalQuery: customQuery,
                    dataSourceName: dsName
                });
            }
            return queries;
        }
    };

    static SetDateParametersForCommandLink = (parentRowData, keys, parameters, timezoneName) => {
        for (let i = 0; i < keys.length; i++) {
            let replaceData = "{" + keys[i] + ".ToString(\"yyyy-MM-dd-startdate\")}";
            if (parameters === null) parameters = "";

            if (parameters.indexOf(replaceData) > -1) {
                let dateVal = new Date(parentRowData[keys[i]]);

                if (!LDH.IsValueEmpty(timezoneName)) {
                    let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    let usDateStr = dateLocaleStr.split("-");
                    let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 0, 0, 0, 0);
                    if (LDH.IsValueEmpty(timezoneName)) timezoneName = "Australia/Sydney";

                    let tz = momenttz().tz(timezoneName).format();
                    let zone = momenttz.parseZone(tz);
                    let offset = zone.utcOffset();
                    let minutes = dateParse.getMinutes();
                    dateParse.setMinutes(minutes - offset);

                    let dateStr = LDH.GetLocalISODateString(dateParse);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                }
            }

            replaceData = "{" + keys[i] + ".ToString(\"yyyy-MM-dd-enddate\")}";
            if (parameters.indexOf(replaceData) > -1) {
                let dateVal = new Date(parentRowData[keys[i]]);

                if (!LDH.IsValueEmpty(timezoneName)) {
                    let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    let usDateStr = dateLocaleStr.split("-");
                    let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 23, 59, 59, 999);

                    let tz = momenttz().tz(timezoneName).format();
                    let zone = momenttz.parseZone(tz);
                    let offset = zone.utcOffset();
                    let minutes = dateParse.getMinutes();
                    dateParse.setMinutes(minutes - offset);

                    let dateStr = LDH.GetLocalISODateString(dateParse);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                }
            }
        }
        return parameters;
    };

    static GetScheduledCustomDataSources(reportDef, args, keys, organizationId) {
        let queries = [];

        if (!LDH.IsObjectNull(reportDef.reportScheduledCustomDataSources)) {
            for (let v = 0; v < reportDef.reportScheduledCustomDataSources.length; v++) {
                let customQuery = reportDef.reportScheduledCustomDataSources[v].query;
                let dsName = reportDef.reportScheduledCustomDataSources[v].name;
                let customParentQuery1 = reportDef.reportScheduledCustomDataSources[v].parentQuery1;
                let customParentQuery2 = reportDef.reportScheduledCustomDataSources[v].parentQuery2;
                let customParentQuery3 = reportDef.reportScheduledCustomDataSources[v].parentQuery3;

                customQuery = LDH.GetSanitizedParameters(args, keys, customQuery, false);
                customQuery = LDH.ReplaceAll(customQuery, "{Input_UserGroupId}", organizationId);
                customQuery = LDH.FilterMacro(customQuery);

                if (!LDH.IsObjectNull(customParentQuery1) && !LDH.IsValueEmpty(customParentQuery1)) {
                    customParentQuery1 = LDH.GetSanitizedParameters(args, keys, customParentQuery1, false);
                    customParentQuery1 = LDH.ReplaceAll(customParentQuery1, "{Input_UserGroupId}", organizationId);
                    customParentQuery1 = LDH.FilterMacro(customParentQuery1);
                }

                if (!LDH.IsObjectNull(customParentQuery2) && !LDH.IsValueEmpty(customParentQuery2)) {
                    customParentQuery2 = LDH.GetSanitizedParameters(args, keys, customParentQuery2, false);
                    customParentQuery2 = LDH.ReplaceAll(customParentQuery2, "{Input_UserGroupId}", organizationId);
                    customParentQuery2 = LDH.FilterMacro(customParentQuery2);
                }

                if (!LDH.IsObjectNull(customParentQuery3) && !LDH.IsValueEmpty(customParentQuery3)) {
                    customParentQuery3 = LDH.GetSanitizedParameters(args, keys, customParentQuery3, false);
                    customParentQuery3 = LDH.ReplaceAll(customParentQuery3, "{Input_UserGroupId}", organizationId);
                    customParentQuery3 = LDH.FilterMacro(customParentQuery3);
                }

                if (!LDH.IsObjectNull(reportDef.reportScheduledCustomDataSources[v].queryShaping) &&
                    !LDH.IsValueEmpty(reportDef.reportScheduledCustomDataSources[v].queryShaping)) {
                    try {
                        eval(reportDef.reportScheduledCustomDataSources[v].queryShaping);
                    } catch (ex) {
                        console.log("Failed to execute queryShaping.");
                    }
                }

                queries.push({
                    parentQuery1: customParentQuery1,
                    parentQuery2: customParentQuery2,
                    parentQuery3: customParentQuery3,
                    finalQuery: customQuery,
                    dataSourceName: dsName
                });
            }
            return queries;
        }
    };

    static GetSanitizedParameters = (args, keys, parameters, updateVariables) => {
        let daylightSaving = 0;
        let timezone = null;
        let timezoneName = "";
        for (let i = 0; i < keys.length; i++) {
            if (keys[i].toLowerCase() === "input_timezone") {
                timezone = args.variables[keys[i]];
            }
            if (keys[i].toLowerCase() === "input_daylightsaving") {
                daylightSaving = args.variables[keys[i]];
            }
            if (keys[i].toLowerCase() === "input_timezonename") {
                timezoneName = args.variables[keys[i]];
            }
        }

        for (let i = 0; i < keys.length; i++) {
            if (keys[i].toLowerCase() === "input_timezoneoffset" &&
                !LDH.IsValueEmpty(timezoneName)) {
                let tz = momenttz().tz(timezoneName).format();
                let zone = momenttz.parseZone(tz);
                let offset = zone.utcOffset() / 60;

                if (updateVariables) {
                    args.variables[keys[i]] = offset;
                }
            }
        }

        window["cc_daylight_saving"] = daylightSaving;
        window["cc_timezone"] = timezone;
        window["cc_timezone_name"] = timezoneName;

        for (let i = 0; i < keys.length; i++) {
            let replaceData = "";
            parameters = LDH.ReplaceAll(parameters, "{" + keys[i] + "}", args.variables[keys[i]]);
            replaceData = "{" + keys[i] + ".ToString(\"yyyy-MM-dd-startdate\")}";

            if (parameters.indexOf(replaceData) > -1) {
                let dateVal = new Date(args.variables[keys[i]]);

                if (LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                    let dateStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    dateStr = dateStr + "T00:00:00.000";
                    dateStr = LDH.ConvertLocalDateToUTCDate(new Date(dateStr));
                    dateStr = LDH.GetLocalISODateString(dateStr);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                } else if (LDH.IsValueEmpty(timezone) && !LDH.IsValueEmpty(timezoneName)) {
                    let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    let usDateStr = dateLocaleStr.split("-");
                    let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 0, 0, 0, 0);

                    let tz = momenttz().tz(timezoneName).format();
                    let zone = momenttz.parseZone(tz);
                    let offset = zone.utcOffset();
                    let minutes = dateParse.getMinutes();
                    dateParse.setMinutes(minutes - offset);

                    let dateStr = LDH.GetLocalISODateString(dateParse);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                } else if (!LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                    let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    let usDateStr = dateLocaleStr.split("-");
                    let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 0, 0, 0, 0);
                    let offset = parseFloat(timezone) + parseFloat(daylightSaving);
                    let hours = dateParse.getHours();
                    dateParse.setHours(hours - offset);

                    let dateStr = LDH.GetLocalISODateString(dateParse);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                }
            }

            replaceData = "{" + keys[i] + ".ToString(\"yyyy-MM-dd-enddate\")}";
            if (parameters.indexOf(replaceData) > -1) {
                let dateVal = new Date(args.variables[keys[i]]);

                if (LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                    let dateStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    dateStr = dateStr + "T23:59:59.999";
                    dateStr = LDH.ConvertLocalDateToUTCDate(new Date(dateStr));
                    dateStr = LDH.GetLocalISODateString(dateStr);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                } else if (LDH.IsValueEmpty(timezone) && !LDH.IsValueEmpty(timezoneName)) {
                    let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    let usDateStr = dateLocaleStr.split("-");
                    let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 23, 59, 59, 999);

                    let tz = momenttz().tz(timezoneName).format();
                    let zone = momenttz.parseZone(tz);
                    let offset = zone.utcOffset();
                    let minutes = dateParse.getMinutes();
                    dateParse.setMinutes(minutes - offset);

                    let dateStr = LDH.GetLocalISODateString(dateParse);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                } else if (!LDH.IsValueEmpty(timezone) && LDH.IsValueEmpty(timezoneName)) {
                    let dateLocaleStr = LDH.ConvertDateToYYYYMMDD(dateVal);
                    let usDateStr = dateLocaleStr.split("-");
                    let dateParse = new Date(usDateStr[0], usDateStr[1] - 1, usDateStr[2], 23, 59, 59, 999);
                    let offset = parseFloat(timezone) + parseFloat(daylightSaving);
                    let hours = dateParse.getHours();
                    dateParse.setHours(hours - offset);

                    let dateStr = LDH.GetLocalISODateString(dateParse);
                    parameters = LDH.ReplaceAll(parameters, replaceData, dateStr);
                }
            }
        }
        return parameters;
    };

    static SetDefaultThemeColors = (documentJson) => {
        if (LDH.IsValueEmpty(documentJson.mainColor)) documentJson.mainColor = "#FF8100";
        if (LDH.IsValueEmpty(documentJson.shadeColor)) documentJson.shadeColor = "#FFF3E7";
        if (LDH.IsValueEmpty(documentJson.hoverSelectionColor)) documentJson.hoverSelectionColor = "#ffd8b5";
        if (LDH.IsValueEmpty(documentJson.topBarColor)) documentJson.topBarColor = "#212529";
        if (LDH.IsValueEmpty(documentJson.workspaceColor1)) documentJson.workspaceColor1 = "#4BAE4F";
        if (LDH.IsValueEmpty(documentJson.workspaceColor2)) documentJson.workspaceColor2 = "#3E50B4";
        if (LDH.IsValueEmpty(documentJson.workspaceColor3)) documentJson.workspaceColor3 = "#785447";
        if (LDH.IsValueEmpty(documentJson.workspaceColor4)) documentJson.workspaceColor4 = "#F34235";
        if (LDH.IsValueEmpty(documentJson.workspaceColor5)) documentJson.workspaceColor5 = "#2195F2";
        if (LDH.IsValueEmpty(documentJson.workspaceColor6)) documentJson.workspaceColor6 = "#FE9700";
        if (LDH.IsValueEmpty(documentJson.workspaceColor7)) documentJson.workspaceColor7 = "#8AC249";
        if (LDH.IsValueEmpty(documentJson.workspaceColor8)) documentJson.workspaceColor8 = "#FE5622";
        if (LDH.IsValueEmpty(documentJson.workspaceColor9)) documentJson.workspaceColor9 = "#02A8F3";
        if (LDH.IsValueEmpty(documentJson.workspaceColor10)) documentJson.workspaceColor10 = "#CCDB39";
        if (LDH.IsValueEmpty(documentJson.workspaceColor11)) documentJson.workspaceColor11 = "#E81D62";
        if (LDH.IsValueEmpty(documentJson.workspaceColor12)) documentJson.workspaceColor12 = "#9B27AF";
        if (LDH.IsValueEmpty(documentJson.workspaceColor13)) documentJson.workspaceColor13 = "#00BBD3";
        if (LDH.IsValueEmpty(documentJson.workspaceColor14)) documentJson.workspaceColor14 = "#FEEA3B";
        if (LDH.IsValueEmpty(documentJson.workspaceColor15)) documentJson.workspaceColor15 = "#6639B6";
        if (LDH.IsValueEmpty(documentJson.workspaceColor16)) documentJson.workspaceColor16 = "#009587";
        if (LDH.IsValueEmpty(documentJson.workspaceColor17)) documentJson.workspaceColor17 = "#FEC007";
        if (LDH.IsValueEmpty(documentJson.workspaceColor18)) documentJson.workspaceColor18 = "#5F7C8A";
    };

    static APIEndpointAdapter = () => {
        if (window.location.hostname !== "localhost" &&
            LDH.IsValidIPAddress(window.location.hostname) === false) {
            let parts = window.location.hostname.split(".");
            let firstPart = parts[0];
            LeopardStaticUIConfig.S3BucketNameForAttachmentStore = firstPart + "-be-attachment-store";
            return "https://api." + window.location.hostname;
        }
        LeopardStaticUIConfig.S3BucketNameForAttachmentStore = "dev10-be-attachment-store";
        return "https://api.dev10.leopardcube.com.au";
    };

    static WebsocketEndpointAdapter = () => {
        if (window.location.hostname !== "localhost" &&
            LDH.IsValidIPAddress(window.location.hostname) === false) {
            return "wss://websocket." + window.location.hostname;
        }
        return "wss://websocket.dev10.leopardcube.com.au";
    };
}

export default LeopardDataHelper;
